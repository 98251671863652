<template>
    <!-- <footer class="footer__main container">
      <div class="row">
        <div class="footer__legal col-sm-8">
          <p class="footer__legal-paragraph">Family Mortgage Team is the Jupiter, Florida branch of The Mortgage Firm, Inc whose main office is located at 601 University Blvd, 104 Jupiter, FL                33458-4812, Telephone: (561) 625-2660</p>
          <p class="footer__legal-paragraph">NLS Corporate Office 189233 | NMLS Jupiter Branch 5616252660</p>
          <p class="footer__legal-paragraph">NMLS Consumer Access: <a href="http://www.nmlsconsumeraccess.org">www.nmlsconsumeraccess.org</a>
        </p></div>
        <p></p>
        <div class="footer__logo col-sm-4">
          <img src="/assets/images/familymortgage_13.png" alt="footer logo">
        </div>
      </div>
    </footer> -->
    <footer class="footer__main container">
        <div class="row">
            <div class="footer__legal col-sm-8">
                <p class="footer__legal-paragraph">Family Mortgage Team is the Jupiter, Florida branch of The Mortgage
                    Firm, Inc whose main office is located at 601 University Blvd, 104 Jupiter, FL 33458-4812,
                    Telephone: (561) 625-2660</p>
                <p class="footer__legal-paragraph">NMLS Corporate Office 189233 | NMLS Jupiter Branch 5616252660</p>
                <p class="footer__legal-paragraph">NMLS Consumer Access:
                    <a href="http://www.nmlsconsumeraccess.org">www.nmlsconsumeraccess.org</a>
                    <br><br>
                    <img src="/assets/images/lender.png" width="80"  alt="Equal Housing Lender">
                </p>
            </div>
            <p></p>
            <div class="footer__logo col-sm-4">
                <img src="/assets/images/familymortgage_13.png" alt="footer logo">
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'ComponentFooter'
    }
</script>

